.form-container {
  padding: 20px;
  & .input-container {
    margin: 20px 0;
  }
  .circular-progress {
    color: #fff;
    width: 30px !important;
    height: 30px !important;
  }
}
