.color-picker-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  & .color-picker {
    margin-top: 20px;
  }
  & .sketch-picker {
    @extend .color-picker;
    z-index: 9999999999999 !important;
  }
  & .chat-circle {
    //background: rgba(105, 50, 250, 255);
    width: 20px;
    height: 20px;
    border-radius: 54px;
    color: white;
    padding: 28px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.04);
    img {
      height: 33px !important;
      width: 45px !important;
      object-fit: cover;
    }
    perspective: 1000px;
    transform-style: preserve-3d;

    transition: all 0.5s ease 0s;
  }
  .color-btns {
    display: flex;
    gap: 15px;
    align-items: center;
    margin-top: 20px;
    text-decoration: underline;
    color: blue;
    & .reset-color-btn {
      width: 6%;
      cursor: pointer !important;
    }
    & .other-options-color-btn {
      @extend .reset-color-btn;
      width: 15%;
    }
  }
}

.js-code {
  position: relative;
  .syntax-highligter {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  .copy-clipboard-icon {
    display: flex;
    align-items: center;
    gap: 10px;
    position: absolute;
    right: 20px;
    top: 20px;
    img {
      width: 35px;
      height: 35px;
      cursor: pointer;
    }
  }
}
